import { Button } from '@mui/material';
import { HomeConfig } from 'Config/home.config';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import { useEnrollForm } from 'Contexts/EnrollProvider/EnrollProvider';
import { useDialog } from 'Contexts/Modals/ModalProvider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Header = () => {
  const { user } = useLogin();
  const { logout } = useLogin();
  const { setStateDwolla } = useEnrollForm();
  const navigate = useNavigate();

  const logoutHandle = () => {
    setStateDwolla(0);
    logout();
  };

  const showApplyButton = false;

  return (
    // aqui se configura el header color fondo
    <nav className='m-0 self-stretch flex flex-col py-2.5 px-0 items-center justify-center text-left text-smi text-white font-inter lg:w-auto lg:[align-self:unset] lg:items-center lg:justify-center md:w-full md:items-center md:justify-center bg-gray-950'>
      <div className='self-stretch flex flex-row items-center justify-center lg:w-full lg:gap-[30px] lg:items-center lg:justify-between lg:min-w-full lg:max-w-full md:w-full md:flex-row md:items-center md:justify-center'>
        <div
          className='flex flex-col items-start justify-start lg:flex-1'
          onClick={() => navigate('/')}
        >
          <img
            className='relative w-auto h-auto max-w-[100px] max-h-[100px] overflow-hidden shrink-0 cursor-pointer sm:max-w-[60px] sm:max-h-[60px] ml-6'
            alt=''
            src={HomeConfig.header.logo}
          />
        </div>

        <nav className='m-0 flex-1 flex flex-row py-0 pr-0 pl-[70px] items-center justify-start  text-left text-mini text-white font-inter lg:flex lg:w-auto lg:[align-self:unset] lg:gap-[10px] lg:items-center lg:justify-center lg:pl-2.5 lg:box-border md:hidden md:pl-0 md:box-border sm:hidden gap-28'></nav>
        <div className='w-[181px] flex flex-col items-end justify-center gap-[10px] lg:w-auto lg:[align-self:unset] lg:items-end lg:justify-center md:items-end md:justify-center sm:w-auto sm:[align-self:unset] sm:items-center sm:justify-right sm:pr-5 sm:box-border'>
          <div className='self-stretch flex flex-row items-center justify-center gap-[10px]'>
            <div className='flex flex-row items-center justify-between gap-[11px]'>
              {user?.token && (
                <div>
                  <Button
                    onClick={logoutHandle}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      padding: '8px 16px',
                      borderRadius: '4px',
                      textTransform: 'none',
                    }}
                  >
                    <ExitToAppIcon style={{ marginRight: '1px' }} />
                    <span style={{ fontWeight: 'bold' }}>Logout</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
          {showApplyButton && (
            <button className='cursor-pointer [border:none] py-[9.389999389648438px] pr-[23.30999755859375px] pl-6 bg-[transparent] self-stretch rounded-81xl [background:linear-gradient(135deg,_#f19126,_rgba(246,_166,_72,_0)),_#E6007E] flex flex-row items-center justify-center md:max-w-[175px]'>
              <div className='flex-1 relative text-mini font-medium font-inter text-white text-center'>
                Enroll Now
              </div>
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};
