// FileUploadButtons.tsx
import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';

import './styles.css';

import { AddCircleOutline, InsertDriveFile } from '@mui/icons-material';
import { type } from 'os';

// @ts-ignorets in
import passport from '../../static/images/icons/passport.png';
// @ts-ignorets in
import driverLincence from '../../static/images/icons/driver-license.png';
// @ts-ignorets in
import idImage from '../../static/images/icons/licence.png';
import { newServer } from 'constants/constants';
import { refactorImgUrl } from 'Helpers/refactor-img-url';
import { updateEnroll } from 'Helpers/update-enroll';
import { useEnrollForm } from 'Contexts/EnrollProvider/EnrollProvider';
import { uploadImage } from 'Helpers/upload-img';

interface FileUploadButtonsProps {
  data?: any;
  onClose: (value: boolean) => void;
  open: boolean;
}

const FileUploadButtons: React.FC<FileUploadButtonsProps> = (props: FileUploadButtonsProps) => {
  const { data, onClose, open } = props;
  const [idType, setIdType] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFile2, setSelectedFile2] = useState<File | null>(null);
  const { enrollData, saveEnrrollData } = useEnrollForm();
  const [loading, setLoading] = useState(false);

  const handleButtonClick = (idType: string) => {
    setIdType(idType);
  };

  const handleCloseDialog = (force: boolean) => {
    if (force) {
      onClose(true);
      return;
    }

    if (idType) {
      setSelectedFile(null);
      setIdType(undefined);
    } else {
      onClose(true);
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>, isBack: boolean) => {
    const file = event.target.files?.[0];

    if (file) {
      if (isBack) {
        setSelectedFile2(file);
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleFileBack = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile2(file);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      setLoading(true);

      saveData();
      //  data.onFileUpload(selectedFile);
      handleCloseDialog(false);
    }
  };

  function addFieldsToObjectIfSSNMatches(obj: any, ssn: string, imgsUrl: any): void {
    // Verificar si el objeto tiene un campo 'ssn'
    if ('ssn' in obj && obj.ssn === ssn) {
      obj.userIdImgs = imgsUrl;
      obj.typeImage = idType;
    }

    // Verificar si el objeto tiene un campo 'owners' que es un array
    if (obj.owners && Array.isArray(obj.owners)) {
      obj.owners.forEach((owner: any) => {
        addFieldsToObjectIfSSNMatches(owner, ssn, imgsUrl);
      });
    }

    // Verificar si el objeto tiene un campo 'controller' que es un objeto
    if (obj.controller && typeof obj.controller === 'object') {
      if (obj.controller.ssn === ssn) {
        obj.userIdImgs = imgsUrl;
        obj.typeImage = idType;
      }
    }
  }

  const saveData = async () => {
    if (!selectedFile) return;

    const imgsUrl = [];
    try {
      imgsUrl.push(await uploadImage(selectedFile));
      if (selectedFile2) {
        imgsUrl.push(await uploadImage(selectedFile2));
      }

      // Llamamos a la función con el objeto y el SSN a buscar
      addFieldsToObjectIfSSNMatches(data.enrollData.data, data.ssn, imgsUrl);

      await updateEnroll(data.user, {
        user_id: data.user?.user_id,
        info_dwolla: { ...data.enrollData.data },
      });

      saveEnrrollData({ ...data.enrollData.data });
      setLoading(false);
      onClose(true);
    } catch (error) {
      setLoading(false);
      console.log('saveData', error);
    }
  };

  return (
    <Grid>
      <Dialog
        open={open}
        // className='dialog'
        // maxWidth='xl'
        fullWidth
      >
        <DialogContent
          className='dialog-container'
          style={{ backgroundColor: '#161d28' }}
        >
          {!idType && (
            <Grid className='flex document-add-container-modal'>
              <Button
                variant='contained'
                className='button'
                onClick={() => handleButtonClick('passport')}
              >
                <img
                  className='icon-card'
                  src={passport}
                />
                <AddCircleOutline className='icon' />
                Passport
              </Button>

              <Button
                variant='contained'
                className='button'
                onClick={() => handleButtonClick('idCard')}
              >
                <img
                  className='icon-card'
                  src={idImage}
                />
                <AddCircleOutline className='icon' />
                ID
              </Button>

              <Button
                variant='contained'
                className='button'
                onClick={() => handleButtonClick('license')}
              >
                <img
                  className='icon-card'
                  src={driverLincence}
                />
                <AddCircleOutline className='icon' />
                Driver's License
              </Button>
            </Grid>
          )}

          {idType === 'license' && (
            <div>
              <DialogTitle style={{ color: 'white' }}>Upload Driver license</DialogTitle>
              <Grid className='flex'>
                <DialogContent className='document-add-container'>
                  <DialogContentText>
                    <span className='icon-text'>
                      {selectedFile ? (
                        <img
                          className='image'
                          src={URL.createObjectURL(selectedFile)}
                        />
                      ) : (
                        <span className='icon-container'>
                          <AddCircleOutline fontSize='large' />
                        </span>
                      )}
                      <div>Upload the front of your document</div>
                    </span>
                  </DialogContentText>
                  <div className='file-input-container'>
                    <label
                      htmlFor='file-input-front'
                      className='file-input-label'
                    >
                      <input
                        type='file'
                        accept='.pdf, .jpg, .jpeg, .png'
                        id='file-input-front'
                        className='file-input'
                        onChange={(e) => handleFileInputChange(e, false)}
                      />
                      Choose File
                    </label>
                  </div>
                </DialogContent>
                <DialogContent className='document-add-container'>
                  <DialogContentText>
                    <span className='icon-text'>
                      {selectedFile2 ? (
                        <img
                          className='image'
                          src={URL.createObjectURL(selectedFile2)}
                        />
                      ) : (
                        <span className='icon-container'>
                          <AddCircleOutline fontSize='large' />
                        </span>
                      )}
                      <div>Upload the back of your document</div>
                    </span>
                  </DialogContentText>
                  <div className='file-input-container'>
                    <label
                      htmlFor='file-input-back'
                      className='file-input-label'
                    >
                      <input
                        type='file'
                        accept='.pdf, .jpg, .jpeg, .png'
                        id='file-input-back'
                        className='file-input'
                        onChange={handleFileBack}
                      />
                      Choose File
                    </label>
                  </div>
                </DialogContent>
              </Grid>
            </div>
          )}
          {idType === 'passport' && (
            <div>
              <DialogTitle style={{ color: '#fff' }}>Upload Passport</DialogTitle>
              <DialogContent className='document-add-container'>
                <DialogContentText>
                  <span className='icon-text'>
                    {selectedFile ? (
                      <img
                        className='image'
                        src={URL.createObjectURL(selectedFile)}
                      />
                    ) : (
                      <span className='icon-container'>
                        <AddCircleOutline fontSize='large' />
                      </span>
                    )}
                    <div>Upload your document</div>
                  </span>
                </DialogContentText>
                <div className='file-input-container'>
                  <label
                    htmlFor='file-input-front'
                    className='file-input-label'
                  >
                    <input
                      type='file'
                      accept='.pdf, .jpg, .jpeg, .png'
                      id='file-input-front'
                      className='file-input'
                      onChange={(e) => handleFileInputChange(e, false)}
                    />
                    Choose File
                  </label>
                </div>
              </DialogContent>
            </div>
          )}
          {idType === 'idCard' && (
            <div>
              <DialogTitle style={{ color: '#fff' }}>National Id</DialogTitle>
              <Grid className='flex'>
                <DialogContent className='document-add-container'>
                  <DialogContentText>
                    <span className='icon-text'>
                      {selectedFile ? (
                        <img
                          className='image'
                          src={URL.createObjectURL(selectedFile)}
                        />
                      ) : (
                        <span className='icon-container'>
                          <AddCircleOutline fontSize='large' />
                        </span>
                      )}
                      <div>Upload the front of your document</div>
                    </span>
                  </DialogContentText>
                  <div className='file-input-container'>
                    <label
                      htmlFor='file-input-front'
                      className='file-input-label'
                    >
                      <input
                        type='file'
                        accept='.pdf, .jpg, .jpeg, .png'
                        id='file-input-front'
                        className='file-input'
                        onChange={(e) => handleFileInputChange(e, false)}
                      />
                      Choose File
                    </label>
                  </div>
                </DialogContent>
                <DialogContent className='document-add-container'>
                  <DialogContentText>
                    <span className='icon-text'>
                      {selectedFile2 ? (
                        <img
                          className='image'
                          src={URL.createObjectURL(selectedFile2)}
                        />
                      ) : (
                        <span className='icon-container'>
                          <AddCircleOutline fontSize='large' />
                        </span>
                      )}
                      <div>Upload the back of your document</div>
                    </span>
                  </DialogContentText>
                  <div className='file-input-container'>
                    <label
                      htmlFor='file-input-back'
                      className='file-input-label'
                    >
                      <input
                        type='file'
                        accept='.pdf, .jpg, .jpeg, .png'
                        id='file-input-back'
                        className='file-input'
                        onChange={(e) => handleFileInputChange(e, true)}
                      />
                      Choose File
                    </label>
                  </div>
                </DialogContent>
              </Grid>
            </div>
          )}

          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              variant='contained'
              style={{
                marginRight: '8px',
                backgroundColor: '#d91c1c',
                color: '#fff',
                width: '500px',
                height: '50px',
                borderRadius: '15px',
                fontSize: '1rem',
              }}
              // style={{ marginRight: '8px', backgroundColor: '#f0f0f0', color: 'black' }}
              onClick={() => handleCloseDialog(true)}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              style={{
                backgroundColor: '#03a9f4',
                color: '#fff',
                width: '500px',
                height: '50px',
                borderRadius: '15px',
                fontSize: '1rem',
              }}
              // style={{ backgroundColor: '#161d28', color: 'white' }}
              onClick={handleUpload}
              disabled={!selectedFile}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  color='inherit'
                />
              )}
              Upload
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default FileUploadButtons;
