import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Card, CardContent, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FuseLoading from '@fuse/core/FuseLoading';
import {
  states,
  processorsURL,
  countryList,
  formcustomer,
  statesAbbreviations,
  WebhookURL,
  ipayNet,
  descriptionBusiness,
  bussinessClasification,
  industryClasification,
} from '../../../constants/constants';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Zoom from '@mui/material/Zoom';
import { useForm } from '../Custom/UseForm';
import FormController from './FormController';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useEnrollForm } from '../../../Contexts/EnrollProvider/EnrollProvider';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import { newServer } from 'constants/constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const phoneregex = RegExp('[0-9]');
const customHeight = {
  width: '100%',
  '& .MuiInputBase-root': {
    height: 52,
  },
};

const CustomTextField = ({ InputLabelProps, sx, ...props }) => {
  const defaultStyles = {
    marginBottom: '10px', // Estilo adicional para TextField
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#161d28', // Cambia el color del borde aquí
      },
      '&:hover fieldset': {
        borderColor: '#fff', // Cambia el color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff', // Cambia el color del borde al enfocar
      },
      color: '#fff',
      backgroundColor: '#2b3a52',
    },
    '& .MuiFormHelperText-root': {
      color: '#d91c1c', // Estilo predeterminado para el helperText
    },
  };

  const defaultInputLabelProps = {
    style: { color: '#fff' }, // Estilo predeterminado para la etiqueta
    ...InputLabelProps, // Permite la sobrescritura o adición de más estilos a InputLabelProps
  };

  return (
    <TextField
      sx={{ ...defaultStyles, ...sx }}
      InputLabelProps={defaultInputLabelProps}
      {...props}
    />
  );
};

export default function FormCustomer({
  setValidate = () => {},
  save = () => {},
  data = {},
  infoDwolla = {},
  resetForm = () => {},
  setCustomerId = () => {},
  _showMessage = () => {},
  setBusinessType = () => {},
  isInvitation = false,
  webhook = '',
}) {
  const regexEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
  const { user } = useLogin();
  const regexUrl = /^https:\/\/[^ "]+$/;
  const { enrollData, saveEnrrollData } = useEnrollForm();
  const { enrollDataUsio, saveEnrrollDataUsio } = useEnrollForm();
  const [businessTypes, setBusinessTypes] = useState([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState('');
  const [businessClasifications, setBusinessClasifications] = useState([]);
  const [selectedBusinessClasification, setSelectedBusinessClasification] = useState('');
  const [disabledIndustries, setDisabledIndustries] = useState(true);
  const [industry, setIndustry] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(moment().subtract(19, 'years').toDate());
  const [dateOfBirthController, setDateOfBirthController] = useState(
    moment().subtract(19, 'years').format('YYYY-MM-DD')
  );
  const [selectState, setSelectState] = useState('');
  const [selectStateController, setSelectStateController] = useState('');
  const [selectCountryController, setSelectCountryController] = useState('US');
  const [controller, setController] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [einNew, setNewEIN] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const bar_no = localStorage.getItem('bar_no');
  const [bar, setBar] = useState(bar_no || '');
  const [businessTypeDwolla, setBusinessTypeDwolla] = useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCheck = ({ target }) => {
    setIsPersonal(target.checked);
  };

  const handleEin = (einNew = '') => {
    if (!einNew.includes('-')) {
      const ein = einNew.slice(0, 2);
      const newEIN = ein + '-' + einNew.slice(2);
      return newEIN;
    }
    return einNew;
  };

  function validarURL(url) {
    // Expresión regular para validar una URL con HTTPS
    // Comprobamos si la URL cumple con el patrón
    if (regexUrl.test(url)) {
      return true; // La URL es válida
    } else {
      return false; // La URL no es válida
    }
  }

  const {
    firstName,
    lastName,
    email,
    ipAddress,
    type,
    ssn,
    address1,
    address2,
    city,
    postalCode,
    businessName,
    doingBusinessAs,
    businessType,
    businessClassification,
    ein,
    website,
    phone,
    correlationId,
    state,
    nameController,
    lastNameController,
    titleContoller,
    ssnController,
    address1Controller,
    address2Controller,
    cityController,
    postalCodeController,
    ownershipPercent,
    numberPassportController,
    onInputChange,
    onResetForm,
    formState,
    setFormState,
  } = useForm({
    firstName: '',
    lastName: '',
    email: '',
    ipAddress: '',
    type: 'business',
    ssn: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    businessName: '',
    doingBusinessAs: '',
    ein: '',
    website: 'https://',
    phone: '',
    correlationId: '',
    ownershipPercent: 0,
    nameController: '',
    lastNameController: '',
    titleContoller: '',
    ssnController: '',
    address1Controller: '',
    address2Controller: '',
    cityController: '',
    postalCodeController: '',
    numberPassport: '',
  });

  const getBusinessClasifications = async () => {
    setDisabledIndustries(true);
    try {
      const response = await fetch(`${processorsURL}/api/dwolla/list-business-classifications`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          project: ipayNet.project,
          x_api_key: ipayNet.X_API_KEY,
        },
      });
      const data = await response.json();
      //TODO Desired Classifications should be a property of the Platform Configuration. (Platform Settings)
      const desiredClassifications = [
        'Pets and animals',
        'Pet Shops, Pet food, and Supplies',
        'Government',
        'Nonprofit',
        'Charity',
        'Services - other',
      ];

      const filteredData = data.filter((entry) => desiredClassifications.includes(entry));

      setBusinessClasifications(filteredData);

      if (infoDwolla?.businessClassification) {
        setSelectedBusinessClasification(infoDwolla?.businessClassification);
        return;
      }

      setSelectedBusinessClasification(bussinessClasification);
    } catch (error) {
      console.log('getBusinessClasifications', error);
    }
  };

  const getIndustries = async (businessType) => {
    setDisabledIndustries(true);

    if (!businessType) return;

    const response = await fetch(
      `${processorsURL}/api/dwolla/list-industries?${new URLSearchParams({
        businessType: businessType,
      })}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          project: ipayNet.project,
          x_api_key: ipayNet.X_API_KEY,
        },
      }
    );
    const data = await response.json();

    // Filter industries based on allowed ones
    const allowedIndustries = {
      Charity: [industryClasification], // TODO: Allowed Industries should be a Platform Setting as well. Here we are defaulting to Charity. is this working?
    };

    const industriesForBusinessType = allowedIndustries[businessType];
    const filteredData = industriesForBusinessType
      ? data.filter((industry) => industriesForBusinessType.includes(industry))
      : data;

    setDisabledIndustries(false);
    setIndustry(filteredData);

    // Check if the selected industry is in the filtered data
    const isSelectedIndustryAllowed =
      infoDwolla?.industry && filteredData.includes(infoDwolla?.industry);
    const isEnrollDataIndustryAllowed =
      enrollData?.data?.industry && filteredData.includes(enrollData?.data?.industry);

    // If businessType is 'other', set selectedIndustry to 'Legal services and attorneys'
    // Otherwise, set it to the allowed industry from infoDwolla or enrollData, or leave it empty
    setSelectedIndustry(
      businessType === bussinessClasification
        ? industryClasification
        : isSelectedIndustryAllowed
        ? infoDwolla?.industry
        : isEnrollDataIndustryAllowed
        ? enrollData?.data?.industry
        : ''
    );
  };

  const saveCustomer = async (relationId = '') => {
    const isValid = validateForm();

    const object = {
      user_id: user?.user_id,
      info_dwolla: createEnrrollData(),
      info_usio: createUsioModelData(),
    };

    if (isValid) {
      return;
    }

    const method = infoDwolla ? 'PUT' : 'POST';
    const response = await fetch(`${newServer}requests`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(object),
    });

    if (response.status != 200) {
      _showMessage(await response.text(), 'error');
      return;
    }

    const data = await response.json();

    setCustomerId(data.id);
    _showMessage('The customer has been created.', 'success', true);
  };

  function createEnrrollData(isValid) {
    const data = {
      firstName,
      lastName,
      email,
      ipAddress,
      type,
      address1,
      address2,
      city,
      postalCode,
      businessName,
      doingBusinessAs: doingBusinessAs,
      businessType: businessTypeDwolla,
      ein,
      website,
      phone: phone.replace(/[^0-9]/g, ''),
      correlationId,
      businessClassification: selectedBusinessClasification,
      state: selectState,
      dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
      industry: selectedIndustry,
      controller: {
        firstName: firstName,
        lastName: lastName,
        title: titleContoller,
        dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
        ssn,
        ownershipPercent,
        address: {
          address1: address1Controller,
          address2: address2Controller,
          city: cityController,
          stateProvinceRegion: selectStateController,
          postalCode: postalCodeController,
          country: selectCountryController,
        },
      },
      relationId: user.uuid,
      webhook_url_external: WebhookURL,
      owners: infoDwolla?.owners || [],
    };

    if (selectedBusinessType == 'Sole Proprietorship') {
      delete data.controller;
      data.ssn = ssn;
    }

    saveEnrrollData({ ...data, isValid });
    return data;
  }

  function createUsioModelData() {
    let ownershipType = '';
    if (selectedBusinessType == 'Sole Proprietorship') {
      ownershipType = 'Sole Prop';
    } else if (selectedBusinessType == 'Partnership') {
      ownershipType = 'Partnership Private';
    } else if (selectedBusinessType == 'Professional Corporation') {
      ownershipType = 'C-Corp Private';
    } else if (selectedBusinessType == 'PLLC') {
      ownershipType = 'LLC Private';
    }

    const data = {
      dbaName: doingBusinessAs,
      legalName: businessName,
      ownershipType: ownershipType,
      fedTaxID: ssn ? ssn : ein,
      businessDesc: descriptionBusiness,
      website: website,
      emailAddress: email,
      addressLine1: address1,
      addressLine2: address2,
      city,
      businessStartDate: dateOfBirth,
      state: selectState,
      postalCode: postalCode,
      busPhoneNo: phone.replace(/[^0-9]/g, ''),
      bankRouteNo: '',
      bankAccountNo: '',
      eComPercent: 100,
      principalPhoneNo: phone.replace(/[^0-9]/g, ''),
      principalFirstName: firstName,
      principalLastName: lastName,
      principalTitle: titleContoller,
      principalAddress1: address1Controller,
      principalAddress2: address2Controller,
      principalCity: cityController,
      principalState: selectStateController,
      principalPostalCode: postalCodeController,
      principalDOB: dateOfBirth ? dateOfBirth : dateOfBirthController,
      principalLast4SSN: ssn,
      ownershipPercent: ownershipPercent,
      webhook_url_external: WebhookURL,
      feesAccountIsPersonal: false,
      owners: [],
    };

    saveEnrrollDataUsio({ ...data });
    return data;
  }

  const saveCustomerController = async (relationId = '') => {
    const formBody = {
      user_id: user?.user_id,
      info_dwolla: createEnrrollData(),
      info_usio: createUsioModelData(),
    };

    const method = infoDwolla ? 'PUT' : 'POST';
    const response = await fetch(`${newServer}requests`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(formBody),
    });

    if (response.status != 200) {
      _showMessage(await response.text(), 'error');
      return;
    }

    const data = await response.json();

    setCustomerId(data.id);
    _showMessage('The customer has been created.', 'success', true);
  };

  const validateForm = () => {
    const isAdult = moment(dateOfBirth).isSameOrBefore(moment().subtract(18, 'years'));
    if (selectedBusinessType == 'Sole Proprietorship') {
      const validate =
        firstName.length > 2 &&
        lastName.length > 2 &&
        regexEmail.test(email) &&
        businessName.length > 2 &&
        ssn.length == 9 &&
        selectedBusinessType?.length > 1 &&
        selectedIndustry?.length > 3 &&
        address1.length > 2 &&
        city.length > 2 &&
        postalCode.length == 5 &&
        selectState?.length > 2 &&
        phone?.length >= 14 &&
        isAdult &&
        validarURL(website) == true
          ? false
          : true;
      setValidate(validate);

      return validate;
    } else {
      const validate =
        firstName.length > 2 &&
        lastName.length > 2 &&
        regexEmail.test(email) &&
        businessName.length > 2 &&
        ownershipPercent <= 100 &&
        ownershipPercent >= 25 &&
        ownershipPercent.length > 0 &&
        selectedBusinessType?.length > 1 &&
        selectedIndustry?.length > 3 &&
        address1.length > 2 &&
        city.length > 2 &&
        postalCode.length == 5 &&
        selectState?.length > 2 &&
        ein.length == 10 &&
        titleContoller.length > 2 &&
        address1Controller.length > 2 &&
        cityController.length > 2 &&
        postalCodeController.length == 5 &&
        phone?.length >= 14 &&
        selectStateController?.length > 2 &&
        isAdult &&
        validarURL(website) == true
          ? false
          : true;
      setValidate(validate);

      return validate;
    }
  };

  useEffect(() => {
    const isValid = validateForm();
    createEnrrollData(isValid);
    save.current = controller ? saveCustomerController : saveCustomer;
  }, [
    selectedBusinessType,
    firstName,
    lastName,
    email,
    businessName,
    ssn,
    selectedBusinessType,
    selectedIndustry,
    address1,
    city,
    postalCode,
    selectState,
    phone,
    ownershipPercent,
    doingBusinessAs,
    website,
    titleContoller,
    address1Controller,
    address2Controller,
    cityController,
    postalCodeController,
    selectCountryController,
    selectStateController,
    ein,
    address2,
    dateOfBirth,
  ]);

  /**
   * Updates the data if already save in the server
   */
  useEffect(() => {
    getBusinessClasifications();
    const newState = { ...formState };

    if (infoDwolla === null && enrollData?.data) {
      infoDwolla = { ...enrollData?.data };
    }

    if (infoDwolla) {
      newState.firstName = infoDwolla?.firstName || '';
      newState.ssn = infoDwolla?.ssn || '';
      newState.lastName = infoDwolla?.lastName || '';
      newState.email = infoDwolla?.email || '';
      newState.address1 = infoDwolla?.address1 || '';
      newState.address2 = infoDwolla?.address2 || '';
      newState.ein = infoDwolla?.ein || '';
      newState.city = infoDwolla?.city || '';
      newState.postalCode = infoDwolla?.postalCode || '';
      newState.phone = formatPhoneNumber('+1' + infoDwolla?.phone) || '';
      newState.businessName = infoDwolla?.businessName || '';
      newState.doingBusinessAs = infoDwolla?.doingBusinessAs || '';
      newState.website = infoDwolla?.website || '';
      newState.nameController = infoDwolla?.controller?.name || '';
      newState.lastNameController = infoDwolla?.controller?.lastNameController || '';
      newState.titleContoller = infoDwolla?.controller?.title || '';
      newState.address1Controller = infoDwolla?.controller?.address.address1 || '';
      newState.address2Controller = infoDwolla?.controller?.address.address2 || '';
      newState.cityController = infoDwolla?.controller?.address.city || '';
      newState.postalCodeController = infoDwolla?.controller?.address.postalCode || '';
      newState.ownershipPercent = infoDwolla?.controller?.ownershipPercent || '';
      newState.type = infoDwolla?.type || '';
      newState.owners = infoDwolla?.owners || '';

      setDateOfBirth(new Date(infoDwolla?.dateOfBirth));
      setSelectState(statesAbbreviations[infoDwolla?.state]);
      setSelectStateController(infoDwolla?.controller?.address?.stateProvinceRegion);
      setSelectedBusinessType(infoDwolla?.businessType);
      setSelectedBusinessClasification(infoDwolla.businessClassification);
      setIndustry(infoDwolla?.industry);
    } else {
      newState.firstName = data?.user_fname || '';
      newState.lastName = data?.user_lname || '';
      newState.email = data?.comp_email || '';
      newState.address1 = data?.compaddress || '';
      newState.city = data?.compcity || '';
      newState.postalCode = data?.compzip || '';
      newState.phone = formatPhoneNumber('+1' + data?.compphone) || '';
      newState.businessName = data?.compname || '';
      newState.doingBusinessAs = data?.dba_name || '';
    }

    setFormState(newState);

    const isValid = validateForm();
    saveEnrrollData({ ...newState, isValid });

    save.current = controller ? saveCustomerController : saveCustomer;
    resetForm.current = onResetForm;

    const ssnCustomer = document.getElementById('ssnCustomer');

    ssnCustomer.addEventListener(
      'select',
      function () {
        this.selectionStart = this.selectionEnd;
      },
      false
    );

    setSelectState('Texas');
    setSelectStateController('Texas');
  }, [infoDwolla]);

  useEffect(() => {
    if (businessTypes.length > 0) return;
    const newBusinessTypes = new Array();
    // newBusinessTypes.push('Sole Proprietorship');
    newBusinessTypes.push('Partnership');
    newBusinessTypes.push('Professional Corporation');
    newBusinessTypes.push('PLLC');
    setBusinessTypes(newBusinessTypes);
  }, []);

  useEffect(() => {
    if (
      selectedBusinessType === 'Partnership' ||
      selectedBusinessType === 'PLLC' ||
      selectedBusinessType === 'Professional Corporation'
    ) {
      setController(true);
    } else {
      setController(false);
    }
  }, [selectedBusinessType]);
  //TODO This useeffect seems to be redundant , as its stating what the "BusinessType" outcome is for Classification and Industry.
  useEffect(() => {
    if (selectedBusinessClasification) getIndustries(selectedBusinessClasification);
    if (selectedBusinessClasification === 'Business to business');
    //TODO Platform Setting, Business Classification
    // setSelectedIndustry('Construction - heavy and civil engineering'); //TODO Platform Setting, Industry Classification
  }, [selectedBusinessClasification]);

  const handleInputNumber = (e, max) => {
    if (e?.target?.value?.length === max + 1) {
      return;
    }
    onInputChange(e);
  };

  if (businessTypes.size === 0) return <FuseLoading />;

  const handleInputNumberPercent = (e, max) => {
    if (e?.target?.value?.length === max + 1) {
      return;
    }
    onInputChange(e);
  };

  const handleAutocompleteChange = (event, newValue) => {
    const uppercaseValue = event.target.value.toUpperCase();
    if (statesAbbreviations[uppercaseValue]) {
      setSelectState(statesAbbreviations[uppercaseValue]);
    } else {
      setSelectState(newValue);
    }
  };

  const handleDateChange = (date) => {
    if (moment().diff(moment(date), 'years') < 18) {
      setErrorMessage('Please enter a valid date of birth');
    } else {
      setErrorMessage('');
    }
    setDateOfBirth(date);
  };

  return (
    <div className='mx-auto py-8'>
      <Card style={{ backgroundColor: '#1e293b', borderRadius: '15px' }}>
        <CardContent>
          <Box sx={{ width: '100%', bgcolor: '#1e293b' }}>
            <Box sx={{ my: 3, mx: 2 }}>
              <Typography
                variant='h5'
                gutterBottom
                component='div'
                style={{ color: '#fff' }}
              >
                Business Information
              </Typography>
            </Box>

            <Box sx={{ my: 3, mx: 2 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                >
                  <Autocomplete
                    options={businessTypes}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        color={selectedBusinessType?.length > 1 ? 'success' : 'error'}
                        required
                        label={'Business Type'}
                        helperText={
                          selectedBusinessType?.length > 1 ? '' : 'Business Type is required'
                        }
                      />
                    )}
                    value={selectedBusinessType}
                    onChange={(event, newInputValue) => {
                      let ownershipBussinesType = '';
                      if (newInputValue == 'Sole Proprietorship') {
                        ownershipBussinesType = 'Sole Proprietorship';
                      } else if (newInputValue == 'Partnership') {
                        ownershipBussinesType = 'Partnership';
                      } else if (newInputValue == 'Professional Corporation') {
                        ownershipBussinesType = 'Corporation';
                      } else if (newInputValue == 'PLLC') {
                        ownershipBussinesType = 'LLC';
                      }
                      setBusinessTypeDwolla(ownershipBussinesType);
                      setSelectedBusinessType(newInputValue);
                      setBusinessType(newInputValue);
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                >
                  <CustomTextField
                    value={businessName}
                    helperText={businessName.length > 4 ? '' : formcustomer.businessName.message}
                    sx={customHeight}
                    color={businessName.length > 4 ? 'success' : 'error'}
                    required
                    name='businessName'
                    fullWidth
                    label='Legal Business Name'
                    variant='outlined'
                    onChange={onInputChange}
                    error={businessName.length <= 4 && businessName}
                    InputProps={{
                      endAdornment: (
                        <Zoom in={businessName.length > 4}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                >
                  <CustomTextField
                    value={doingBusinessAs}
                    sx={customHeight}
                    name='doingBusinessAs'
                    fullWidth
                    label='Doing Business As'
                    variant='outlined'
                    onChange={onInputChange}
                    InputProps={{
                      endAdornment: (
                        <Zoom in={doingBusinessAs.length >= 2}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ my: 3, mx: 2 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                >
                  <Autocomplete
                    freeSolo
                    options={businessClasifications}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        color={selectedBusinessClasification?.length > 2 ? 'success' : 'error'}
                        required
                        label={'Business Classification'}
                      />
                    )}
                    value={selectedBusinessClasification}
                    onChange={(event, newInputValue) => {
                      setSelectedBusinessClasification(newInputValue);
                      setSelectedIndustry(undefined);
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                >
                  <Autocomplete
                    disabled={disabledIndustries}
                    freeSolo
                    options={industry}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        color={selectedIndustry?.length > 2 ? 'success' : 'error'}
                        required
                        label={'Industry'}
                      />
                    )}
                    value={selectedIndustry}
                    onChange={(event, newInputValue) => {
                      setSelectedIndustry(newInputValue);
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                >
                  {controller ? (
                    <CustomTextField
                      value={ein}
                      sx={customHeight}
                      name='ein'
                      fullWidth
                      type={'text'}
                      color={controller ? (ein?.length == 10 ? 'success' : 'error') : ''}
                      helperText={controller ? (ein?.length <= 8 ? 'Invalid EIN format' : '') : ''}
                      onKeyPress={(e) => {
                        const isNumber = /\d/.test(e.key);
                        if (!isNumber) {
                          e.preventDefault();
                        }
                      }}
                      onKeyDown={(e) => {
                        if ((Number(e.key) >= 0 && Number(e.key) < 10) || e.key == 'Backspace') {
                          if (e.key == 'Backspace') {
                            const value = e.target.value.replace(/-/g, '');
                            e.target.value = value;
                          } else {
                            if (e.target.value.length == 8) {
                              const newEIN = handleEin(e.target.value);
                              e.target.value = newEIN;
                            } else {
                              e.target.value = e.target.value;
                            }
                          }
                        }
                      }}
                      onKeyUp={(e) => {
                        if (Number(e.key) >= 0 && Number(e.key) < 10) {
                          if (e.target.value.length === 8) {
                            const newEIN = handleEin(e.target.value);
                            onInputChange({
                              target: { name: 'ein', value: newEIN },
                            });
                          }
                        }
                      }}
                      label={controller ? 'EIN *' : 'EIN'}
                      variant='outlined'
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                      inputProps={{
                        inputMode: 'numeric',
                        min: '0',
                        max: '100',
                        maxLength: 10,
                      }}
                      error={ein?.length < 10 && ein}
                      InputProps={{
                        endAdornment: (
                          <Zoom in={ein.length == 10}>
                            <IconButton
                              edge='end'
                              aria-label='check'
                            >
                              <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                            </IconButton>
                          </Zoom>
                        ),
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ my: 3, mx: 2 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                >
                  <CustomTextField
                    value={address1}
                    sx={customHeight}
                    name='address1'
                    helperText={address1.length > 2 ? '' : formcustomer.address.message}
                    fullWidth
                    label='Address 1 '
                    variant='outlined'
                    onChange={onInputChange}
                    required
                    color={address1.length > 2 ? 'success' : 'error'}
                    error={address1?.length < 2 && address1}
                    InputProps={{
                      endAdornment: (
                        <Zoom in={address1.length > 2}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={2}
                >
                  <CustomTextField
                    value={address2}
                    sx={customHeight}
                    name='address2'
                    fullWidth
                    label='Address 2 (Optional) '
                    variant='outlined'
                    onChange={onInputChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={2}
                >
                  <CustomTextField
                    value={city}
                    sx={customHeight}
                    helperText={city.length > 2 ? '' : formcustomer.city.message}
                    required
                    color={city.length > 2 ? 'success' : 'error'}
                    error={city?.length < 2 && city}
                    name='city'
                    fullWidth
                    label='City'
                    variant='outlined'
                    onChange={onInputChange}
                    InputProps={{
                      endAdornment: (
                        <Zoom in={city.length > 2}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={2}
                >
                  <Autocomplete
                    options={states}
                    renderInput={(params) => (
                      <CustomTextField
                        helperText={selectState?.length > 3 ? '' : formcustomer.state.message}
                        required
                        color={selectState?.length > 3 ? 'success' : 'error'}
                        error={selectState?.length < 3 && selectState}
                        {...params}
                        label={'State'}
                        onChange={handleAutocompleteChange}
                      />
                    )}
                    value={selectState}
                    onChange={(event, newInputValue) => {
                      setSelectState(newInputValue);
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={2}
                >
                  <CustomTextField
                    value={postalCode}
                    required
                    helperText={postalCode.length == 5 ? '' : formcustomer.zip.message}
                    color={postalCode.length == 5 ? 'success' : 'error'}
                    error={postalCode.length <= 4 && postalCode}
                    InputProps={{
                      inputMode: 'numeric',
                      endAdornment: (
                        <Zoom in={postalCode.length == 5}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                    sx={customHeight}
                    name='postalCode'
                    fullWidth
                    label='Postal Code'
                    variant='outlined'
                    pattern='[0-9]*'
                    type={'number'}
                    onChange={(e) => {
                      handleInputNumber(e, 5);
                    }}
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    placeholder={'12345'}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ my: 3, mx: 2 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                >
                  <CustomTextField
                    value={phone}
                    sx={customHeight}
                    name='phone'
                    fullWidth
                    required
                    label='Phone'
                    variant='outlined'
                    helperText={phone.length >= 14 ? '' : 'Phone is required'}
                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    placeholder={'0000000000'}
                    color={phone.length <= 14 ? 'success' : 'error'}
                    onInput={(e) => {
                      if (!phoneregex.test(e.target.value.slice(-1))) {
                        e.target.value = e.target.value.slice(0, -1);
                      }

                      const newphone = formatPhoneNumber('+1' + e.target.value);

                      if (newphone.length > 1) {
                        e.target.value = newphone;
                      } else {
                        e.target.value = e.target.value;
                      }
                    }}
                    error={phone.length < 14 && phone}
                    inputProps={{
                      maxLength: 14,
                      inputMode: 'numeric',
                    }}
                    InputProps={{
                      endAdornment: (
                        <Zoom in={phone.length >= 14}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                >
                  <CustomTextField
                    value={website}
                    sx={customHeight}
                    name='website'
                    fullWidth
                    required
                    helperText={validarURL(website) ? '' : 'Check the URL'}
                    color={validarURL(website) ? 'success' : 'error'}
                    label='Website'
                    variant='outlined'
                    onChange={onInputChange}
                    error={!validarURL(website) && website}
                    placeholder='https://www.domain.com'
                    InputProps={{
                      endAdornment: (
                        <Zoom in={validarURL(website)}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ my: 3, mx: 2 }}>
              <>
                {' '}
                <Typography
                  variant='h5'
                  gutterBottom
                  component='div'
                  style={{ color: '#fff' }}
                >
                  Owner / Enroller -
                </Typography>
                <br />
                <Typography
                  variant='h6' //TODO Make smaller , and ADD tool Tip Type of icon.
                  gutterBottom
                  component='div'
                  style={{ color: '#fff' }}
                >
                  Who is completing the Enrollment Process (Additional Owners will be added Next)
                </Typography>
              </>
            </Box>

            <Box sx={{ my: 3, mx: 2 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={3}
                >
                  <CustomTextField
                    value={email}
                    helperText={regexEmail.test(email) ? '' : formcustomer.email.message}
                    onChange={onInputChange}
                    name='email'
                    sx={customHeight}
                    fullWidth
                    label='Email'
                    required
                    color={regexEmail.test(email) ? 'success' : 'error'}
                    variant='outlined'
                    type={'email'}
                    error={!regexEmail.test(email) && email}
                    InputProps={{
                      endAdornment: (
                        <Zoom in={regexEmail.test(email)}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2.5}
                  lg={2.5}
                >
                  <CustomTextField
                    value={firstName}
                    sx={customHeight}
                    name='firstName'
                    fullWidth
                    label='First Name'
                    helperText={firstName.length > 2 ? '' : formcustomer.firstName.message}
                    required
                    color={firstName.length > 2 ? 'success' : 'error'}
                    error={firstName.length <= 2 && firstName}
                    variant='outlined'
                    onChange={onInputChange}
                    inputProps={{
                      maxLength: 25,
                    }}
                    InputProps={{
                      endAdornment: (
                        <Zoom in={firstName.length > 2}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2.5}
                  lg={2.5}
                >
                  <CustomTextField
                    value={lastName}
                    helperText={lastName.length > 2 ? '' : formcustomer.lastName.message}
                    sx={customHeight}
                    name='lastName'
                    color={lastName.length > 2 ? 'success' : 'error'}
                    error={lastName.length <= 2 && lastName}
                    required
                    fullWidth
                    label='Last Name'
                    variant='outlined'
                    onChange={onInputChange}
                    inputProps={{ maxLength: 25 }}
                    InputProps={{
                      endAdornment: (
                        <Zoom in={lastName.length > 2}>
                          <IconButton
                            edge='end'
                            aria-label='check'
                          >
                            <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                          </IconButton>
                        </Zoom>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={2}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={dateOfBirth}
                      onChange={handleDateChange}
                      label='Date Of Birth *'
                      sx={{
                        marginBottom: '10px', // Estilo adicional para TextField
                        '& .MuiInputLabel-root': {
                          color: '#fff', // Cambia el color del texto del label a blanco
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#161d28', // Cambia el color del borde aquí
                          },
                          '&:hover fieldset': {
                            borderColor: '#fff', // Cambia el color del borde al pasar el mouse
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#fff', // Cambia el color del borde al enfocar
                          },
                          color: '#fff',
                          backgroundColor: '#2b3a52',
                        },
                        '& .MuiFormHelperText-root': {
                          color: '#d91c1c', // Estilo predeterminado para el helperText
                        },
                      }}
                    />
                    <div style={{ color: 'red' }}>{errorMessage}</div>
                  </LocalizationProvider>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={2}
                >
                  <CustomTextField
                    value={ssn}
                    sx={{ ...customHeight }}
                    name='ssn'
                    id='ssnCustomer'
                    label='SSN'
                    placeholder='123456789'
                    variant='outlined'
                    required
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    color={ssn.length === 9 ? 'success' : 'error'}
                    error={ssn.length < 9 && ssn}
                    helperText={ssn.length === 9 ? '' : formcustomer.ssn.message}
                    onChange={onInputChange}
                    InputProps={{
                      inputMode: 'numeric',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 9, inputMode: 'numeric' }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ my: 3, mx: 2 }}>
              <Grid
                container
                spacing={2}
              >
                {controller && (
                  <FormController
                    handleChange={onInputChange}
                    states={states}
                    customHeight={customHeight}
                    infoDwolla={infoDwolla}
                    // nameController={nameController}
                    // lastNameController={lastNameController}
                    ssnController={ssnController}
                    titleContoller={titleContoller}
                    dateOfBirthController={dateOfBirthController}
                    setDateOfBirthController={setDateOfBirthController}
                    address1Controller={address1Controller}
                    address2Controller={address2Controller}
                    cityController={cityController}
                    postalCodeController={postalCodeController}
                    numberPassportController={numberPassportController}
                    countryList={countryList}
                    setSelectStateController={setSelectStateController}
                    selectStateController={selectStateController}
                    selectCountryController={selectCountryController}
                    setSelectCountryController={setSelectCountryController}
                    handleInputNumber={handleInputNumber}
                  />
                )}
              </Grid>
            </Box>

            <Box sx={{ my: 3, mx: 2 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                >
                  {controller ? (
                    <>
                      <CustomTextField
                        value={ownershipPercent}
                        onChange={(e) => {
                          handleInputNumberPercent(e, 3);
                        }}
                        inputProps={{
                          inputMode: 'numeric',
                          min: '25', // Valor mínimo actualizado a 25
                          max: '100',
                        }}
                        name='ownershipPercent'
                        sx={customHeight}
                        fullWidth
                        label='Ownership percentage'
                        required
                        type='number'
                        helperText={
                          ownershipPercent >= 25 &&
                          ownershipPercent <= 100 &&
                          ownershipPercent.length > 0 // Actualizar la lógica de validación
                            ? ''
                            : 'Please input a value within 25 and 100% representing ownership percentage' // Mensaje de error actualizado
                        }
                        color={
                          ownershipPercent >= 25 &&
                          ownershipPercent <= 100 &&
                          ownershipPercent.length > 0 // Actualizar la lógica de color
                            ? 'success'
                            : 'error'
                        }
                        variant='outlined'
                        InputProps={{
                          endAdornment: (
                            <Zoom
                              in={
                                ownershipPercent >= 25 &&
                                ownershipPercent <= 100 &&
                                ownershipPercent.length > 0
                              }
                            >
                              <IconButton
                                edge='end'
                                aria-label='check'
                              >
                                <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                              </IconButton>
                            </Zoom>
                          ),
                        }}
                      />
                    </>
                  ) : null}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                >
                  {controller ? (
                    <CustomTextField
                      value={bar}
                      onChange={(e) => {
                        setBar(e.target.value);
                      }}
                      name='ownershipPercent'
                      sx={customHeight}
                      fullWidth
                      label='Bar No'
                      required
                      variant='outlined'
                      InputProps={{
                        endAdornment: (
                          <Zoom in={bar.length > 2}>
                            <IconButton
                              edge='end'
                              aria-label='check'
                            >
                              <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                            </IconButton>
                          </Zoom>
                        ),
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
