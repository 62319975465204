import {
  Paper,
  Typography,
  TextField,
  Button,
  Icon,
  Card,
  CardContent,
  Alert,
  Snackbar,
} from '@mui/material';
// import { error } from 'console';
import { ipayNet, newServer } from 'constants/constants';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Close, Email, FilterVintage } from '@mui/icons-material';
import { HomeConfig } from 'Config/home.config';

export const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [confirm_password, setconfirm_password] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const email = query.get('email');

  const navigateHome = () => {
    navigate('/');
  };

  const handleSubmit = async () => {
    if (password !== confirm_password) {
      setError('Passwords do not match');
    } else {
      setError('');
      // Handle the password change logic here
      const res = await fetch(`${newServer}password/reset/${token}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': ipayNet.apiToken,
        },
        body: JSON.stringify({
          email,
          password,
          confirm_password,
        }),
      });

      if (res.status === 500) {
        setError('Error: please try again');
        return;
      }

      if (res.status === 200) {
        navigateHome();
      } else {
        setError(await res.text());
      }
    }
  };

  return (
    <Card className='login-form'>
      <Card className={'card-container'}>
        {alertMessage && <Alert severity='error'>{alertMessage}</Alert>}
        <CardContent className='card-content'>
          <Typography
            variant='h5'
            align='center'
            gutterBottom
          >
            <Icon style={{ color: '#161d28', fontSize: '36px', marginTop: '10px' }}>
              <FilterVintage />
            </Icon>
            Reset Password
          </Typography>
          <Typography
            variant='body1'
            align='center'
            color='textSecondary'
            paragraph
          >
            Log-In if already enrolled to {HomeConfig.merchatName}{' '}
          </Typography>

          <TextField
            fullWidth
            variant='outlined'
            label='Password'
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin='normal'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            variant='outlined'
            label='Confirm Password'
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirm_password}
            onChange={(e) => setconfirm_password(e.target.value)}
            margin='normal'
            error={!!error}
            helperText={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              style={{ marginTop: 20, backgroundColor: '#161d28', color: '#ffffff' }}
              // add this to ensure that the hover effect color changes accordingly
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#e6008a')}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#161d28')}
            >
              Update Password
            </Button>
          </div>

          <Typography
            variant='body2'
            align='center'
            color='textSecondary'
            style={{ marginTop: '15px' }}
          >
            <a
              className='link'
              onClick={() => navigate('/terms')}
            >
              {' '}
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a
              className='link'
              onClick={() => navigate('/policies')}
            >
              Privacy Policy
            </a>
            .
          </Typography>
        </CardContent>
      </Card>
    </Card>
  );
};
