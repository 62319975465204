export const HomeConfig = {
  merchatName: 'LupitaPay',
  header: {
    logo: './logoAbogadoPago.png', //TODO Switch Logo File Reference and relabel.
    menuItems: [
      // { name: 'Lost Pet', path: '/' },
      // { name: 'Adopt a Pet', path: '/' },//TODO Hide for now.
      // { name: 'Donate', path: '/' }, //TODO Donate general. (Public through "noahsarkdonate" to "Enrolled" Non Profits.)
    ],
  },
  banner: {
    bannerImg: './logo_lupitaPay.png',
    bannerTitle: ['Simplifying Payments', 'for ', 'Attorneys.'],
    bannerDescription:
      'Welcome to LupitaPay, where we understand the complexities attorneys face when managing payments. With us, you can ensure that your hard-earned money is received swiftly and securely.',
  },
  features: [
    { title: 'Donation Forms', icon: '/ico/1.-Donation.png' },
    { title: 'Text Alerts', icon: '/ico/2.Text.png' },
    { title: 'Reunite Lost Pets', icon: 'ico/3.-Reunite.png' },
    { title: 'Community Forms', icon: '/ico/4.-Community.png' },
    { title: 'Adopting Fostering', icon: '/ico/5.-Adopting.png' },
  ],
  section1: {
    title: 'Empowering Animal Advocates: Fundraising & Reconnection',
    description:
      "Noahs Ark Donate offers a cutting-edge platform, ensuring a smooth, contemporary, and impactful giving experience for your donors. But that's not all. Seamlessly integrated within our platform are Noah's Ark features: a dedicated service for reuniting lost pets with their families and another for facilitating pet adoptions and fostering. ",
    description2:
      "Engage in a system that trims down costs, bolsters your mission's outreach, and makes the donation process a breeze, allowing every second you save to focus directly on the animals and their stories.",
    features: [
      {
        logo: '/ico/support.png',
        title: 'Support',
        logo2: '/ico/no long-term contracts.png',
        title2: 'No Long-Term Contracts',
      },
      {
        logo: '/ico/support.png',
        title: 'Multiple Payment Avenues for Donors',
        logo2: '/ico/multiple payment avenues for doners.png',
        title2: 'No Long-Term Contracts',
      },
    ],
    bannerImg: '/Propuesta 1.png',
    bannerDescription:
      "Our experience with Noah's Ark has been exceptional. Its user-friendly interface offers a smooth experience when rallying support for our furry friends.",
    bannerAuthor: 'Dolores Esperenaza',
    bannerAuthorTitle: 'Community Affairs,',
    bannerCompanyName: 'iPaysolutions',
  },
  section2: {
    title: "3 Key reasons Animal Rescues choose Noah's Ark for Fundraising",
    items: [
      {
        mainTitle: 'SEAMLESS DONATIONS',
        subtitle: 'Collection & Checkout',
        features: [
          '- Customizable Checkout',
          '- Web & Mobile Checkout',
          ' - Automatic Email Receipts with conditional content to recongize donors for specific actions during the giving processes',
          '- Encourage Recurring Giving',
          '- Fundraising Campaign Paylinks.',
        ],
      },
      {
        mainTitle: 'LOST PETS',
        subtitle: ' Keeping that Bond alive!',
        features: [
          '- Saving Pet Lives by reuniting with Pet Owners',
          ' - Connecting the Community and empowering social impact',
          '- Pet ID & Enrollment',
        ],
      },
      {
        mainTitle: 'COMMITMENT-FREE TERMS COMMUNITY',
        subtitle: '',
        features: [
          '- Event Creation & Organizing',
          '- Event Management',
          '- Embed an event registration button directly on your nonprofit’s website',
          '- Secure Payments Accept Visa, Mastercard, Discover,American Express, ACH, and Apple Pay',
          '- Collect Donations, Ticket Sales, Registrations',
          ' - Ticketing Issuance , and Attendance Tracking',
          '- Manage Attendees',
        ],
      },
    ],
    title2: "Noah's Ark to champion animal welfare",
  },
  section3: {
    title:
      " Transforming animal lives is our shared mission. Join Noah's Ark today and amplify your impact.",
    bannerImg: './fondo/footer.png',
    button: { text: 'Enroll', path: '/enroll' },
  },

  footer: {
    logo: '',
    description:
      'Crafted by legal minds: With LupitaPay efficiently manage payments to champion your clients causes fervently. Designed by attorneys to fuel unwavering representation for every client.',
    phone: '+19562252534',
    email: 'info@pagoabogado.com',
    time: [{ title: 'Monday - Friday', schedule: '9am - 7pm CST' }],
    option: [],
  },
};
