import EnrollmentSteps from 'components/EnrollmentSteps/EnrollmentSteps';
import Footer from 'components/Footer';
import { Header } from 'components/Header/Header';

export default function DwollaApp({ store }: any) {
  return (
    <>
      <Header />
      <div
        style={{
          height: '100%',
          minHeight: '100vh',
          maxHeight: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className='bg-gray-950'
      >
        <EnrollmentSteps />
      </div>
    </>
  );
}
