import { Typography, Grid, Card } from '@mui/material';

const ConfirmationMenssage = () => {
  return (
    <Grid
      container
      style={{
        height: '100%',
        minHeight: '80vh',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}
      className='bg-gray-950'
    >
      <Card
        style={{
          backgroundColor: '#1e293b',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          lineHeight: '1.6',
        }}
        className='bg-gray-950'
      >
        <Typography
          variant='h6'
          align='center'
          gutterBottom
          style={{ color: '#fff' }}
        >
          Great news – your application is in the review stage. We're thrilled about the prospect of
          having you on board and are eager to offer you a top-notch digital transaction experience.
          Stay tuned! We'll keep you updated on the progress of your merchant application and will
          notify you as soon as a decision is made. Thank you for choosing us, and we're excited
          about the journey ahead! Warm regards
        </Typography>
      </Card>
    </Grid>
  );
};

export default ConfirmationMenssage;
