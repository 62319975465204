export const BackofficeTermsPolicies = () => {
  return <div className='bg-white'>
    <div className=' text-[25px] max-w-5xl mx-auto p-4 font-inter'>

      <h1 className='text-center '>Privacy Statement</h1>
      <br />
      <p>Last Revised: 11/01/2024</p>
      <br />
      <p>paymylawyer.cc ("Pay My Lawyer LLC") is committed to protecting your privacy while providing quality services
        that give you an enjoyable and safe online experience. This Privacy Statement (this "Statement") applies to the
        Pay My Lawyer LLC website at http://www.paymylawyer.cc and governs the data collection and usage by Pay My
        Lawyer LLC in connection with this website. By using the Pay My Lawyer LLC website, you consent to the data
        practices described in this Statement.</p>
      <br />
      <h2>Collection of Information about You</h2>
      <br />
      <p>Pay My Lawyer LLC collects personally identifiable information ("PII"), such as your e-mail address, physical
        address, name, and phone number, and credit card information, on its website when you register, save a design,
        or place an order. Pay My Lawyer LLC also may collect non-personally identifiable information, such as general
        geographic information, about you.</p>

      <p>Pay My Lawyer LLC may also passively collect information about your computer hardware and software. This
        information may include: your IP address, browser type, domain names, access times and referring website
        addresses, Pay My Lawyer LLC templates made, activity on the site, operating system information, and other
        similar information. This information is used by Pay My Lawyer LLC for the operation of its services, to
        maintain quality of service, and to provide general statistics regarding use of the Pay My Lawyer LLC website.
      </p>

      <p>Pay My Lawyer LLC encourages you to review the privacy statements of websites you choose to visit from links that
        may be posted on the Pay My Lawyer LLC website so you can understand how those websites collect, use, and share
        your information. Pay My Lawyer LLC is not responsible for the privacy statements or other content on websites
        outside of the Pay My Lawyer LLC website.</p>
      <br />
      <h2>Use of Information about You</h2>
      <br />
      <p>Pay My Lawyer LLC collects and uses information about you, including PII, and your use of the Pay My Lawyer LLC
        website to operate the Pay My Lawyer LLC website and affiliated websites and to deliver the services you have
        requested. Pay My Lawyer LLC also uses PII from time to time to inform you of other products or services
        available from Pay My Lawyer LLC and its affiliates. Pay My Lawyer LLC may also contact you via surveys to
        conduct research about your opinion of current services or of potential new services. Note that you may opt out
        of being contacted by Pay My Lawyer LLC for any research or product updates at any time through links at the
        bottom of every email from Pay My Lawyer LLC .</p>
      <p>Pay My Lawyer LLC may, from time to time, contact you on behalf of third-party business partners about a
        particular offering that may be of interest to you. Pay My Lawyer LLC also uses third-party companies to help
        serve targeted product recommendations. These companies may use data about you collected from this site in order
        to provide recommendations for products and services that might be of interest to you.</p>
      <p>To learn more about behavioral advertising or to opt out of this type of advertising, you can visit
        http://networkadvertising.org. You may also opt out by calling us at 1-800-330-9622. In addition, Pay My Lawyer
        LLC may share data, including PII, with trusted partners to help us perform statistical analysis, send you email
        or postal mail, provide customer support, or arrange for deliveries. Pay My Lawyer LLC requires these third
        parties to maintain the confidentiality of your information and to not use your PII except to provide services
        to Pay My Lawyer LLC . Pay My Lawyer LLC keeps track of the websites and pages our customers visit within and
        from the Pay My Lawyer LLC website in order to determine what Pay My Lawyer LLC services are the most popular.
        This data is used to deliver customized content and advertising from Pay My Lawyer LLC to customers whose
        behavior indicates that they are interested in a particular subject area.</p>
      <p>Pay My Lawyer LLC will not use or disclose sensitive personally identifiable information about you, such as race,
        religion, or political affiliations, without your express consent. Pay My Lawyer LLC does not collect PII from
        you to track you over time and across third party websites. As a result, Pay My Lawyer LLC does not specifically
        respond to web browser Do Not Track signals.</p>
      <p>Pay My Lawyer LLC uses Google Analytics to collect and process certain analytics data; Google provides some
        additional privacy options described at http://www.google.com/policies/privacy/partners/.</p>
      <p>Pay My Lawyer LLC will disclose personally identifiable information about you without notice to you only if
        required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the
        requirements of the law or comply with legal process served on Pay My Lawyer LLC or the site; (b) protect and
        defend the rights or property of Pay My Lawyer LLC ; and, (c) act under exigent circumstances to protect the
        personal safety of Pay My Lawyer LLC , Pay My Lawyer LLC users, or the public.</p>
      <br />
      <h2>Automated Processing and AI-Powered Services</h2>
      <br />
      <p>Pay My Lawyer LLC may use automated tools, including artificial intelligence (AI), to assist in processing data,
        generating insights, and providing service enhancements. These tools may analyze your data to offer product
        recommendations, customer support improvements, and predictive analytics based on aggregate information. When
        automated tools impact your personal information, they are used solely to enhance your experience with our
        website and services. Pay My Lawyer LLC applies all applicable safeguards to protect the data processed by these
        tools, and we do not use AI tools to make decisions affecting your rights without prior notice and consent.</p>
      <br />
      <h2> Additional Tracking Technologies:</h2>
      <br />
      <p>
        In addition to cookies, Pay My Lawyer LLC may use other tracking technologies, such as pixel tags, web beacons,
        or similar technologies, to monitor your interaction with our website and emails. These technologies allow us to
        measure the effectiveness of our website and email communications, tailor content, and understand user
        engagement trends. If you prefer not to allow these additional tracking technologies, you may modify your
        browser settings or opt out via our privacy settings."
      </p>
      <br />
      <h2>Use of Cookies</h2>
      <br />
      <p>The Pay My Lawyer LLC website may use "cookies" to help you personalize your online experience. A cookie is a
        text file that is placed on your hard disk by a web server. Cookies cannot be used to run programs or deliver
        viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the
        domain that issued the cookie to you.</p>
      <p>
        One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a
        cookie is to tell the website server that you have returned to a specific page. For example, if you register
        with the Pay My Lawyer LLC website or services, a cookie helps Pay My Lawyer LLC recall your specific
        information on subsequent visits. This simplifies the process of recording your personally identifiable
        information, such as your billing addresses, shipping addresses, and other similar information. When you return
        to the same Pay My Lawyer LLC web site, the information you previously provided can be retrieved, so you can
        easily use the Pay My Lawyer LLC features that you have customized.
      </p>
      <p>You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can
        usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may
        not be able to fully experience the interactive features of the Pay My Lawyer LLC services or websites you
        visit.</p>
      <br />
      <h2>California Consumer Privacy Protection Act ("CCPA")</h2>
      <br />
      <p>Pay My Lawyer LLC does not sell "personal information"; however, Pay My Lawyer LLC collects the following
        personal information from California residents solely for "business purposes," as that term is defined in the
        CCPA:</p>
      <ul>
        <li>Name</li>
        <li>Physical Address</li>
        <li>Email Address</li>
        <li>Phone Number</li>
        <li>IP Address</li>
        <li>Behavioral Data</li>
      </ul>
      <p>
        The specific business purposes for which the above data is collected and shared are as follows:
      </p>
      <p>Order Fulfillment</p>
      <p>Marketing of Pay My Lawyer LLC products</p>
      <p>Any service providers used in the course of fulfilling the above business purposes are contractually obligated to
        use personal information received from Pay My Lawyer LLC solely for the purpose of providing the service
        providers’ service to Pay My Lawyer LLC .</p>
      <p>You have the right to request that we delete any of your personal information that we collected from you and
        retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will
        delete (and direct our service providers to delete) your personal information from our records, unless an
        exception applies.</p>
      <p>You may exercise your rights under the CCPA by calling us at 1-855-487-6771 or by email us at
        support@paymylawyer.cc.</p>
      <br />
      <h2>Security</h2>
      <br />
      <p>Pay My Lawyer LLC provides reasonable security from unauthorized access, use or disclosure for the PII we store.
        Pay My Lawyer LLC aims to secure the PII you provide using computer servers in a controlled, secure environment,
        reasonably protected from unauthorized access, use or disclosure. When certain sensitive PII (such as a credit
        card number) is transmitted to other websites, such as payment information in connection with transactions
        through the Pay My Lawyer LLC website, we use encryption, such as the Secure Socket Layer (SSL) protocol, in an
        effort to protect that information.</p>
      <p>Pay My Lawyer LLC will not record, store, or save your credit card number without providing notice to you and
        receiving your express consent.</p>
      <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best
        to protect your personal information, we cannot guarantee the security of your personal information transmitted
        to the Pay My Lawyer LLC website. Any transmission of personal information is at your own risk. We are not
        responsible for circumvention of any privacy settings or security measures contained on the Pay My Lawyer LLC
        website.</p>
      <br />
      <h2>Changes to this Statement</h2>
      <br />
      <p>Pay My Lawyer LLC may occasionally update this Privacy Statement to reflect changes in our practices or
        applicable law. Pay My Lawyer LLC encourages you to periodically review this Statement to be informed of how Pay
        My Lawyer LLC is protecting your information. If Pay My Lawyer LLC modifies this Statement, we will make it
        available through this website, and indicate the date of the latest revision. In the event that the
        modifications materially alter your rights or obligations hereunder, Pay My Lawyer LLC will make reasonable
        efforts to notify you of the change. For example, Pay My Lawyer LLC may send a message to your email address, if
        we have one on file, or generate a pop-up or similar notification when you access the website for the first time
        after such material changes are made. Your continued use of the Pay My Lawyer LLC website after the revised
        Statement has become effective indicates that you have read, understood and agreed to the current version of
        this Statement.</p>

      <div >
        <br />
        <h2>Contact Information</h2>
        <br />
        <p>Pay My Lawyer LLC welcomes your questions and comments regarding this Statement. If you believe that Pay My
          Lawyer LLC has not adhered to this Statement, please use the inquiry form to contact us.</p>

      </div>
    </div>
  </div>
};//TODO Terms & Policies must be a dyanmically configurable WYSIWIG. We may be changing our Terms and Policy from time to time. 
