import { useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Icon,
  IconButton,
  InputAdornment,
  SnackbarContent,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import {
  Email,
  Lock,
  FilterVintage,
  AccountCircle,
  Visibility,
  VisibilityOff,
  Close,
  Info,
  PermIdentity,
} from '@mui/icons-material';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import InformationDialog from 'Dialogs/Information/Information';
import { useDialog } from 'Contexts/Modals/ModalProvider';
import './styles.css';
import { ipayNet, newServer } from 'constants/constants';
import { useNavigate } from 'react-router-dom';
import { HomeConfig } from 'Config/home.config';
import { useLocation } from 'react-router-dom';
import HttpsIcon from '@mui/icons-material/Https';
import LockIcon from '@material-ui/icons/Lock';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const loginWith2FA = true;

const AuthForm = ({ isModal }: any) => {
  const { setUser } = useLogin();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [_2fa, set2fa] = useState();
  const [_barNo, setBarNo] = useState('');
  const [visivility, setVisivility] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const location = useLocation();
  const isEmailValid = emailRegex.test(email);
  /**
   * Handlers Functions
   */
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleForgotPasswordOpen = async () => {
    const subdomain = '';
    openDialog('reset-password', InformationDialog as any, {
      title: 'Reset Password',
      description:
        'Please enter the email address associated with your account and We will email you a link to reset your password.',
      icon: <HttpsIcon />,
      input: forgotPasswordEmail,
      setInput: (value: string) => setForgotPasswordEmail(value as any),
      inputPlaceholder: 'Enter email',
      cancelText: 'Cancel',
      onConfirm: (value: string) => handlePasswordReset(value, subdomain),
    });
  };

  const showModal = () => {
    openDialog('information', InformationDialog as any, {
      title: 'Login Completed',
      description: 'User created successfully',
      icon: <Info />,
    });
  };

  const showModal2fa = () => {
    openDialog('information', InformationDialog as any, {
      title: 'Verify 2FA (Two Factor Authentication)  Code',
      description:
        'To complete the login process, kindly verify the two-factor authentication code sent to your registered email or phone number. Please enter the code in the below field.',
      icon: <Info />,
      input: _2fa,
      setInput: set2fa,
      inputPlaceholder: '2FA code',
      cancelText: 'Cancel',
      onConfirm: validate2FA,
    });
  };

  const validate2FA = async (code: string) => {
    if (code) {
      try {
        const res = await fetch(`${newServer}/login/2FA`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code: code,
            remember_me: false,
          }),
        });

        if (res.status === 200) {
          const data = await res.json();

          if (data?.token && data.token.error === undefined) {
            setUser({
              email,
              token: data.token,
              user_id: data?.user.id,
              uuid: data?.user.uuid,
              bar_no: data?.user.bar_no,
            });
          } else {
            setAlertMessage(`Login failed. ${data?.token.info.message}.  Please try again.`);
            setSnackbarMessage('Login failed. Please try again.');
            setOpenSnackbar(true);
          }
          return;
        }

        setAlertMessage(await res.text());
      } catch (error) {
        console.log('validate2FA', error);
      }
    }
  };

  const getDomain = async () => {
    try {
      const response = await fetch(`${newServer}subdomain/get-domain-by-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': ipayNet.apiToken,
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.text();
      return data;
    } catch (error) {
      console.log('getDomain', error);
    }
  };

  const handleLogin = async () => {
    const subdomain = await getDomain();
    const user = { email, password, subdomain: subdomain };
    setLoading(true);
    setAlertMessage('');

    fetch(`${newServer}login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': ipayNet.apiToken,
      },
      body: JSON.stringify(user),
    })
      .then(async (response) => {
        setLoading(false);
        if (response.status === 429) {
          // const { message } = await response.json();
          setSnackbarMessage(
            `Error: We've noticed some unusual activity coming from your IP address, so we've put a temporary pause on access as a safety measure. Don't worry, it's just a timeout! Give it a little while and try again. Need help sooner? Our support team is here for you.`
          );
          setOpenSnackbar(true);
          return;
        }
        if (response.status === 200) {
          if (loginWith2FA && (await response.text()) === 'OK') {
            showModal2fa();
            return;
          }

          const data = await response.json();

          if (data?.token && data.token.error === undefined) {
            setUser({ email, token: data.token, user_id: data?.user_id, uuid: data?.uuid });
          } else {
            setAlertMessage(`Login failed. ${data?.token.info.message}.  Please try again.`);
            setSnackbarMessage('Login failed. Please try again.');
            setOpenSnackbar(true);
          }
        } else {
          const textMessage = await response.text();
          setAlertMessage(`Login failed. ${textMessage}.  Please try again.`);
          setSnackbarMessage('Login failed. Please try again.');
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('handleLogin', error);
        setAlertMessage(`Login failed.`);
        setSnackbarMessage('Login failed. Please try again.');
        setOpenSnackbar(true);
      });
  };

  const handleRegister = () => {
    setVisivility(true);
    if (_barNo !== '' && _barNo) {
      const user = {
        email,
        password,
        subDomain: 'subdominio1',
        subDomainName: 'Shelter 1',
        bar_no: _barNo,
      };
      setLoading(true);
      setAlertMessage('');

      fetch(`${newServer}users/simple`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': ipayNet.apiToken,
        },
        body: JSON.stringify(user),
      })
        .then(async (response) => {
          setLoading(false);
          if (response.status === 200) {
            if (loginWith2FA && (await response.text()) === 'OK') {
              showModal2fa();
              return;
            }

            const data = await response.json();

            if (data?.token) {
              showModal();
              setUser({
                email,
                token: data.token,
                user_id: data?.user_id,
                uuid: data?.uuid,
                bar_no: data?.user.bar_no,
              });
            }
          } else {
            const textMessage = await response.text();
            console.error('Registration failed', textMessage);
            setAlertMessage(`Registration failed. ${textMessage}.  Please try again.`);
            setSnackbarMessage('Registration failed. Please try again.');
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log('handleRegister', error);
          setAlertMessage(`Registration failed. ${error}`);
          setSnackbarMessage('Registration failed. Please try again.');
          setOpenSnackbar(true);
        });
    } else {
      setSnackbarMessage('Please enter your Bar Card');
      setAlertMessage('Please enter your Bar Card');
      setOpenSnackbar(true);
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordReset = async (email: string, subdomain: any) => {
    try {
      const res = await fetch(`${newServer}password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, subdomain }),
      });
      if (res.status === 200) {
        setSnackbarMessage('Password reset link sent to your email!');
        setOpenSnackbar(true);
      } else {
        setSnackbarMessage('Error resetting password. Please try again.');
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage('Error resetting password. Please try again.');
      setOpenSnackbar(true);
    }
  };

  return (
    <Card
      style={{
        borderRadius: '15px', // Esto aplica el borderRadius al contenedor del input
        padding: '20px',
        minWidth: '300px',
        maxWidth: '500px',
        margin: 'auto',
        backgroundColor: '#1e293b',
        height: '100%',
      }}
    >
      {alertMessage && <Alert severity='error'>{alertMessage}</Alert>}
      <CardContent>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust as needed
          onClose={handleSnackbarClose}
        >
          <SnackbarContent
            message={<span>{snackbarMessage}</span>}
            action={
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={handleSnackbarClose}
              >
                <Icon fontSize='small'>
                  <Close />
                </Icon>
              </IconButton>
            }
          />
        </Snackbar>

        <Typography
          variant='h5'
          align='center'
          color='#fff'
          gutterBottom
        >
          <Icon style={{ color: '#fff', fontSize: '36px', marginTop: '10px' }}>
            <FilterVintage />
          </Icon>
          Registration Required
        </Typography>
        <Typography
          variant='body1'
          align='center'
          color='#fff'
          paragraph
        >
          Log-In if already enrolled to {HomeConfig.merchatName}{' '}
        </Typography>

        {visivility && (
          <TextField
            label='Bar Card'
            variant='outlined'
            fullWidth
            margin='normal'
            required
            value={_barNo}
            onChange={(e) => setBarNo(e.target.value)}
            InputLabelProps={{
              style: { color: '#fff' }, // Cambia el color de la etiqueta aquí si es necesario
            }}
            InputProps={{
              startAdornment: (
                <Icon style={{ color: '#fff', marginRight: '8px' }}>
                  <PermIdentity />
                </Icon>
              ),
              style: {
                backgroundColor: '#161d28',
                borderRadius: '15px', // Esto aplica el borderRadius al contenedor del input
                color: '#fff',
              },
            }}
          />
        )}

        <TextField
          label='Email'
          variant='outlined'
          type='email'
          fullWidth
          required
          margin='normal'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{
            style: { color: '#fff' }, // Cambia el color de la etiqueta aquí si es necesario
          }}
          InputProps={{
            startAdornment: (
              <Icon style={{ color: '#fff', marginRight: '8px' }}>
                <Email />
              </Icon>
            ),
            style: {
              backgroundColor: '#161d28',
              borderRadius: '15px', // Esto aplica el borderRadius al contenedor del input
              color: '#fff',
            },
          }}
        />

        <TextField
          label='Password'
          required
          fullWidth
          margin='normal'
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputLabelProps={{
            style: { color: '#fff' }, // Cambia el color de la etiqueta aquí si es necesario
          }}
          InputProps={{
            startAdornment: (
              <Icon style={{ marginRight: '8px' }}>
                <Lock style={{ color: '#fff' }} />
              </Icon>
            ),
            style: {
              backgroundColor: '#161d28',
              borderRadius: '15px',
              color: '#fff',
            },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={handlePasswordVisibility}
                  edge='end'
                  color='primary'
                  aria-label='toggle password visibility'
                >
                  {showPassword ? (
                    <Visibility style={{ color: '#fff' }} />
                  ) : (
                    <VisibilityOff style={{ color: '#fff' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleLogin}
            disabled={!isEmailValid || loading}
            style={{
              borderColor: '#fff',
              color: '#fff',
              marginRight: '10px',
              cursor: 'pointer',
              width: '500px',
              height: '50px',
              borderRadius: '15px',
              backgroundColor: isEmailValid ? '#03a9f4' : '#161d28',
            }}
          >
            {loading && (
              <CircularProgress
                size={24}
                color='inherit'
              />
            )}
            <Icon style={{ color: '#fff', marginRight: '5px', marginBottom: '5px' }}>
              <LockIcon />
            </Icon>
            Login
          </Button>

          {location.pathname !== '/backoffice' && (
            <Button
              variant='contained'
              color='primary'
              disabled={!isEmailValid || loading}
              onClick={handleRegister}
              style={{
                borderColor: '#fff',
                color: '#fff',
                cursor: 'pointer',
                width: '500px',
                height: '50px',
                borderRadius: '15px',
                backgroundColor: isEmailValid ? '#03a9f4' : '#161d28',
              }}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  color='inherit'
                />
              )}
              <Icon
                style={{
                  color: '#fff',
                  marginRight: '5px',
                  marginBottom: '5px',
                }}
              >
                <AccountCircle />
              </Icon>
              Enroll Now
            </Button>
          )}
        </div>

        <Typography
          variant='body2'
          align='center'
          color='textSecondary'
          style={{ cursor: 'pointer', marginTop: '15px' }}
          onClick={handleForgotPasswordOpen}
        >
          <a className='link'>Forgot Password</a>
        </Typography>

        <Typography
          variant='body2'
          align='center'
          color='#fff'
          style={{ marginTop: '30px' }}
        >
          By enrolling you accept our
          <a
            className='link'
            onClick={() => navigate('/terms')}
          >
            {' '}
            Terms and Conditions
          </a>{' '}
          and our{' '}
          <a
            className='link'
            onClick={() => navigate('/policies')}
          >
            Privacy Policy
          </a>
          .
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AuthForm;
