import { Button, Card, Grid, Typography } from '@mui/material';
import AuthForm from 'components/AuthForm/AuthForm';
import { Header } from 'components/Header/Header';
import EnrollmentListTable from 'components/Moderator/enrollsList/EnrollsList';
import { useEffect, useState } from 'react';
import './styles.css';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import { ipayNet, newServer } from 'constants/constants';
import EnrollItem from 'components/Moderator/EnrollItem';
import { HomeConfig } from 'Config/home.config';

const Backoffice = () => {
  const { user, logout } = useLogin();
  const [currentEnrrol, setCurrentEnrrol] = useState();
  const [enrolls, setEnrolls] = useState<any>([]);
  const [requestInfo, setRequestInfo] = useState();
  const [page, setpage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);

  useEffect(() => {
    if (user?.token) {
      fetchEnrollments(page);
    }
  }, [user]);

  const fetchEnrollments = async (page: number) => {
    try {
      const response = await fetch(`${newServer}requests/1?page=${page}&limit=${limit}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
          'x-api-key': ipayNet.apiToken,
        },
      });

      try {
        const res = await response.json();

        if (res.message === 'Access denied') {
          logout();
          return;
        }
        setRequestInfo(res?.data);

        setEnrolls(res?.data?.map((item: any) => ({ ...item.info_dwolla, id: item.id })));
        return;
      } catch (error) {
        if (response.status === 200) {
          return response;
        }

        console.error(JSON.stringify(error));
      }
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  const paginateContent = (page: number) => {
    setpage(page);
    fetchEnrollments(page);
  };

  const goBack = () => {
    setCurrentEnrrol(undefined);
    fetchEnrollments(page);
  };

  return (
    <Grid
      className='bg-gray-100'
      style={{ minHeight: '100vh' }}
    >
      <Header />
      {!user?.token && (
        <>
          <Typography
            variant='h4'
            align='center'
            className='auth-title'
          >
            Welcome to {HomeConfig.merchatName} Backoffice
          </Typography>
          <AuthForm />
        </>
      )}
      {user?.token && (
        <div>
          {!currentEnrrol && enrolls && (
            <div>
              <EnrollmentListTable
                data={enrolls}
                requestInfo={requestInfo}
                onApprove={function (index: number): void {}}
                onDeny={function (index: number): void {}}
                setCurrentEnrrol={setCurrentEnrrol}
              />

              <div className='pagination'>
                <Button
                  variant='contained'
                  className='buttons'
                  onClick={() => paginateContent(page - 1)}
                  disabled={page === 1}
                >
                  -
                </Button>
                {page}
                <Button
                  className='buttons'
                  style={{
                    marginRight: '8px',
                    backgroundColor: '#161d28',
                    color: 'white',
                  }}
                  onClick={() => paginateContent(page + 1)}
                >
                  +
                </Button>
              </div>
            </div>
          )}
          {currentEnrrol && (
            <EnrollItem
              data={currentEnrrol as any}
              goBack={goBack}
            />
          )}
        </div>
      )}
    </Grid>
  );
};

export default Backoffice;
