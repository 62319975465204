import { Button } from '@mui/material';

export const RoundedButton = ({ children, ...props }: any) => {
  return (
    <Button
      {...props}
      style={{
        margin: 10,
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.2s ease',
        backgroundColor: '#03a9f4',
        color: 'white',
        height: '40px',
        minWidth: '110px',
        ':hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
        ...(props.disabled && {
          backgroundColor: '#03a9f4',
          cursor: 'not-allowed',
        }),
      }}
    >
      {children}
    </Button>
  );
};
